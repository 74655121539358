.bg {
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: white;
    background-size: 25px 25px, 25px 25px, 5px 5px, 5px 5px;
    background-position: -2px -2px, -2px -2px, -2px -2px, -2px -2px;
    background-image: linear-gradient(#ebebeb 1px, transparent 0px), linear-gradient(90deg,#ebebeb 1px, transparent 0px), linear-gradient(#f7f7f7 1px, transparent 0px), linear-gradient(90deg, #f7f7f7 1px, transparent 0px);
}